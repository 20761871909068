/*=== Modulo Contacto ===*/

#contactoIcon {
    display: inline-block;
    width: 50px;
    font-size: 22px;
    text-align: center;
    background: #F6F6F6;
    float: left;
    border-radius: 4px 0 0 4px;
}

#contactoFormulario {
    display: inline-block;
    width: 310px;
    background: #FFF;
    padding: 11px;
    border: 1px solid #F6F6F6;
}

#contacto {
    background: transparent;
    position: fixed;
    right: -310px;
    top: 128px;
    color: #464545;
    z-index: 1000;
}

#contacto:hover {
    animation-name: animacionContact;
    animation-duration: 7s;
    transform-origin: 80% 20%;
    -webkit-animation-name: animacionContact;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 80% 20%;
    -moz-animation-name: animacionContact;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 80% 20%;
    -o-animation-name: animacionContact;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 80% 20%;
    -ms-animation-name: animacionContact;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 80% 20%;
}


@keyframes animacionContact{
    0% {
        right: 0px;
    }
    100% {
        right: 0px;
    }
}

@-webkit-keyframes animacionContact{
    0% {
        right: 0px;
    }
    100% {
        right: 0px;
    }
}

@-moz-keyframes animacionContact{
    0% {
        right: 0px;
    }
    100% {
        right: 0px;
    }
}

@-o-keyframes animacionContact{

    0% {
        right: 0px;
    }
    100% {
        right: 0px;
    }
}

@-ms-keyframes animacionContact{
    0% {
        right: 0px;
    }
    100% {
        right: 0px;
    }
}
