html{
    position: relative;
}
body{
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #fff;
    padding-bottom: 50px;
    height: auto;
}
.logo{
    padding: 5px;
}
#footer {
    background-color: #375A7F;
    color: #FFF;
    padding: 10px;
    position: absolute;
    height: 40px;
    bottom: 0;
    width: 100%;
}
.navbargreen{
     background-color: #10B1B6;
     border-color: #10B1B6;
 }

/*===  Menú principal ===*/ 
.navbar-default .navPrincipal .navbar-nav > li > a{
    color: #989898;
}
.navbar-default  .navPrincipal .navbar-nav > li > a:hover, .navbar-default .navPrincipal .navbar-nav > li > a:focus {
    color: #8EBC11;
    background-color: transparent;
}
.navPrincipal > ul:first-child > li{
    border-right: 1px solid #DCDCDC;
}
.navPrincipal{
    border: 1px solid #DCDCDC;
    text-align: center;
}

/*=== Buscar  ====*/
.search{
    background: #57B956;
    height: 69px;
    width: 110px;
    line-height: 58px;
    border-bottom: 2px solid #367338;
}

/*=== Periodo ===*/
i.periodo{
    background: #989898;
    color: rgb(255, 255, 255);
    padding: 3px 7px;
    border-radius: 40%;
}
i.periodo.active{
    background: #375A7F;
}
i.periodo:hover{
    background: #4672A2;
}

.user{
    color: #E1F7A8;
    margin-right: 20px;
}

.list{
	color: #FFF;
	height: 0px;
	font-size: 1.5em;
	padding-right: 15px;
	border-width: 0px 30px 50px 0px;
	border-style: solid;
	border-color: transparent transparent #375A7F;
}

/*=== Tablas ====*/
.table-info, .table-info th {
    text-align: center;
}
.table-info{
    text-align: center;
    border: 1px solid #868695;
}

.table-list{
    border: 1px solid #8EBC11;
}
.table-list tr th{
    background: #8EBC11;
    color: #FFFFFF;
    font-size: 1.1em;
}

/*=== Navegación ===*/

.nav.nav-more {
    margin-right: 0;
}
.nav.nav-icon i{
    margin-left: 10px;
} 

.nav.nav-more> li > a{
    padding-top: 3px;
    padding-bottom: 3px;
    line-height: 10px;
}
.nav.nav-more > li > a:hover, .nav.nav-more> li > a:focus {
    text-decoration: none;
    background-color: transparent;
}

ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
}

.itemInforme{
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin: 5px;
    float: left;
}

/*=== Perfil ===*/
.perfil{
	margin-top: 10px;
}

.nav-perfil li{
    background: #375A7F;
    color: #FFF;
}
.nav-perfil li.active, .nav-perfil li a:hover{
    background: #7990A9;
}
.nav-perfil li a:hover{
    color: #E8E8E8;
}

.nav-perfil li a{
    color: #FFF;
}

.itemPerfil {
    padding: 0;
}

.fotoPerfil {
    float: left;
    padding: 10px;
    text-align: center;
    width: 180px;
}

.perfil .table td{
    border: 1px solid transparent;
}

.dropdown-menu2{
    top: 0;
    right: initial;
    left: 158px;
    float: right;
}

.tiny{
    padding: 0 3px;
    height: 28px;
}

.pointer{
    cursor: pointer;
}


/*Personalizada*/

.cep {
    background-color: #58799c;
    border-color: #5b7188;
}

.cpa {
    background-color: #57b956;
    border-color: #04a502;
}
.lpc {
    background-color: rgb(112, 124, 185);
    border-color: #454e80;
}
.lpp {
    background-color: rgb(95, 45, 75);
    border-color: #580035;
}
.lpi {
    background-color: #256382;
    border-color: #00334c;
}